import { LOCAL_STORAGE_USER_META_KEY } from "./Constants";

const server_base_url = "https://api.bbbeacon.com/api/v1";
//const server_base_url = "https://trademonkey.duckdns.org/api/v1";
const wp_base_url = "https://www.bbbeacon.com/wp-json/custom/v1";

// save system
async function wordpressMetaRequest(method, key, value)
{
    let payload = value ? {
        meta: {
            [key]: value,
        }
    } : null;

    let url = `${wp_base_url}/users/me/custom_meta`;

    //if (method === 'GET')
    //    url += "?key=" + encodeURIComponent(key);

    //console.log("wordpressMetaRequest", method, key, payload);  

    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'X-WP-Nonce': window.WP_NONCE,
        },
        credentials: 'include',
        ...(payload !== null && { body: JSON.stringify(payload) }),
    });

    return response;
}

export async function getUserMeta()
{
    if (process.env.NODE_ENV === 'development')
        return null;

    const storedJson = localStorage.getItem(LOCAL_STORAGE_USER_META_KEY);
    if (storedJson){
        const storedData = JSON.parse(storedJson);

        return storedData;
    }    

    const response = await wordpressMetaRequest('GET', null, null);

    if (!response.ok)
    {
        const error = new Error('Error while trying to get user meta data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }    

    const data = await response.json();

    localStorage.setItem(LOCAL_STORAGE_USER_META_KEY, JSON.stringify(data.meta));

    return data.meta;

}

export async function setUserMeta(key, value)
{
    if (process.env.NODE_ENV === 'development')    
        return;
    //console.log("set zoom: ", value);
    const storedJson = localStorage.getItem(LOCAL_STORAGE_USER_META_KEY);
    if (storedJson){
        const storedData = JSON.parse(storedJson);
        storedData[key] = value;
        localStorage.setItem(LOCAL_STORAGE_USER_META_KEY, JSON.stringify(storedData));
    }

    const response = await wordpressMetaRequest('POST', key, value);

    if (!response.ok)
    {
        const error = new Error('Error while trying to set user meta data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const data = await response.json();

    //console.log("set user meta: " + data);
    return data;

}


// wordpress calls
 async function fetchToken()
{   
    //console.log('wp nonce ', window.WP_NONCE);
    //console.log('fetch token');


    const response = await fetch(`${wp_base_url}/token`, {
        headers: {
            'Content-Type': 'application/json',
            'X-WP-Nonce': window.WP_NONCE,
        },
        credentials: 'include',        
    });
   

    if (!response.ok)
    {
        const error = new Error('Error while trying to get wp nonce');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const tokenData = await response.json();

    return tokenData;
}

let tokenPromise = null;
async function getAuthToken()
{
    //mdeacu token
    if (process.env.NODE_ENV !== 'production')
        return 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJuYW1lIjoiMCJ9LCJuYmYiOjE3Mjc1OTg3MDQsImlhdCI6MTcyNzU5ODcwNCwiZXhwIjoxNzI3NjIwMzA0LCJpc3MiOiJ0cmFkZW1vbmtleS5kdWNrZG5zLm9yZyIsImF1ZCI6InRyYWRlbW9ua2V5OmNoYXJ0cyJ9.V2opK_X-qWTxy9q5B-bP-ey-vpLFbnUn-5AR3mytFIJ6dzpiDBcPQLLdq1OQF5sxYNSbQATs4mT4UTn_gHckT_07Ls4pljMNqMoCieSt2ozqnA0yjgieZfgRVeZsPIW-PtSJOaOv0efAoxeikoA5R-qBq1dZMY4nMwTmeZl4PGIHNGpRENX4zFmVFVhqsa6-4MbZVcXoZEewnrST33TsCy4b5pPmPz9HcKzxlHCDKY44K_OZHCwvmHeWqm76i2iOTzNGa9RE7Hl-rnwfyvs_XzPPqP2Y_P29AqRRwClSCtcv20SpvPmXVJpxv8BOZeaX8kauAOlA_9Rvz5mbtrlwjQ';

    let token = localStorage.getItem('token');
    //console.log('loadedToken', token);

    if (!token) {
        if (!tokenPromise) {
            tokenPromise = fetchToken().then(fetchedTokenData => {
                if (fetchedTokenData.data && fetchedTokenData.data.token){
                    token = fetchedTokenData.data.token;
                    localStorage.setItem('token', token);
                    tokenPromise = null;                                                                                                                                                                                                            
                    return token;
                }
                else
                    return null;
            }).catch(err => {
                tokenPromise = null;
                throw err;
            });
        }
        token = await tokenPromise;
    }    

    return token;
}

// server calls
async function fetchServerRequest(url, body)
{
    const token =  await getAuthToken();

    const response = await fetch(`${server_base_url}/${url}`, {
        method: body ? 'POST' : 'GET',
        headers: {
            'Authorization' :  `Bearer ${token}` ,
            'Content-Type': 'application/json',
        },
        ...( body && {
            body: JSON.stringify(body)
        }),
    });

    return response;
}

async function checkTokenFetchServerRequest(url, body = null)
{
    let response = await fetchServerRequest(url, body);

    //console.log('response: ', response);
    if (response.status === 403){
        const responseBody = await response.text();
        //console.log('response body: ', responseBody);
        if (responseBody.includes("token is expired")) {
            //console.log('trying to remove...');
            localStorage.removeItem('token');
            response = fetchServerRequest(url);            
        }
    }

    return response;

}

export async function fetchHistoricChart(ticker, metrics, extra_params = '', startDate, endDate)
{   
    //console.log('startDate: ', startDate);
    //console.log('endDate: ', endDate);
    const response = await checkTokenFetchServerRequest(`historic?asset=${ticker}&format=json/list&metrics=${metrics.join(',')}` + 
        (startDate !== null ? `&start_date=${startDate}` : '') + 
        (endDate !== null ? `&end_date=${endDate}` : '') +
        `&${extra_params}`);

    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch historical data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const historicData = await response.json();

    return historicData;
}

export async function fetchAssets()
{   
    const response = await checkTokenFetchServerRequest('charts/assets');

    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const historicData = await response.json();

    return historicData;
}

export async function fetchAssetsDailyGains(assets)
{   
    //console.log('assets: ', assets);
    const response = await checkTokenFetchServerRequest('assets/price', {
        assets,
    });

    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const historicData = await response.json();

    return historicData;
}


export async function fetchHistoricMeta()
{   
    const response = await checkTokenFetchServerRequest('historic/info');
   
    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const metaData = await response.json();

    return metaData;
}

export async function fetchDailyMeta()
{   
    const response = await checkTokenFetchServerRequest('charts/info');
   
    if (!response.ok)
    {
        const error = new Error('Error while trying to fetch assets');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const metaData = await response.json();

    return metaData;
}

export async function fetchDailyChart(ticker, date, charts, price){

    const response = await checkTokenFetchServerRequest(`charts?asset=${ticker}&format=json/list` + 
        `&date=${date}` + 
        (price ? `&price=${price}`:'') +
        `&chart_type=${charts.join(',')}`);
    
        if (!response.ok)
    {
        const error = new Error('Error while trying to fetch daily data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const dailyData = await response.json();

    return dailyData;
}

export async function fetchMarketBreadthChart(startDate, endDate){

    const response = await checkTokenFetchServerRequest(`market_bredth/v2` + 
        (startDate != null ? `&start_date=${startDate}` : '') + 
        (endDate != null ? `&end_date=${endDate}` : ''));
    
        if (!response.ok)
    {
        const error = new Error('Error while trying to fetch market breadth chart');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const marketBreadthData = await response.json();

    return marketBreadthData;
}


export async function fetchOverviewData(ticker, date){

    const response = await checkTokenFetchServerRequest(`overview?asset=${ticker}&format=json/list` + 
        `&date=${date}`);
    
        if (!response.ok)
    {
        const error = new Error('Error while trying to fetch daily data');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const overviewData = await response.json();

    return overviewData;
}