export const SAVE_KEY_HISTORICAL_DATE_INTERVAL = 'BBHistoricalDateInterval';
export const SAVE_KEY_HISTORICAL_METRICS = 'BBHistoricalMetrics';
export const SAVE_KEY_FAVORITE_TICKERS = 'BBFavoriteTickers';
export const SAVE_KEY_DAILY_DATE = 'BBPositioningDate';
export const SAVE_KEY_CHARTS_PAGE_TAB = 'BBChartsTab';
export const SAVE_KEY_MARKET_BREADTH_DATE_INTERVAL = 'BBMarketBreadthDateInterval';

export const LOCAL_STORAGE_USER_META_KEY = 'user_meta';

// export const SAVE_KEY_POSITIONING_DATEchartsTabDate';
// export const SAVE_KEY_VOLUME_DATE = 'volumeDate';
// export const SAVE_KEY_OVERVIEW_DATE = 'volumeDate';

